<template>
	<div class="search-wrapper fav-search" @click="toggleModal" v-show="!isMobile">
		<img class="search-icon fav-search-icon" src="../../../static/images/icons/icon_search.png"  alt=""/>
		<input class="search-input"  type="text" :placeholder="$t('SEARCH')"/>
	</div>
	<div class="fav-card__container flex-col"  :class="!isNoData ? 'display-flex' : 'display-flex-center'">
		<p class="fav-subtitle" v-if="isNoData">
			{{ isMobile ? $t("TAP_ON") : $t("CLICK_ON") }}
			<img class="fav-icon__sample" src="../../../static/images/icons/icon-search-grey.png"  alt="" />{{ isMobile ? $t("THEN_TAP") : $t("THEN_CLICK") }}
			<img class="fav-icon__sample" src="../../../static/images/icons/icon-star-grey.png" alt="" />{{ $t("ADD_TEAM") }}
		</p>
		<template v-if="isNoData">
			<FavCard type="sample" name="Team A" />
		</template>
		<template v-else>
			<FavCard
				v-for="c in favList"
				:key="c.id"
				:type="isTab"
				:name="c.name"
				:id="c.id"
				:logo="c.logo"
				:isFav="c.isFav"
				@favHandler="favHandler"
			/>
		</template>
	</div>
 
	<Modal @closeModal="toggleModal" :tabName="isTab" />

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "./components/Modal.vue";
import FavCard from "./components/FavCard.vue";

export default {
  components: {
    Modal,
    FavCard,
  },
  data() {
    return {
      isTab: "TEAMS",
      favList: "",
      isNoData: false,
    };
  },
  mounted() {
    this.commonVerifyLogin();
    if (this.isLogin) {
       this.init();
    }
  },
  watch: {
 	  userSwitchCountryLang: {
      deep: true,
      handler(newVal, oldVal) {
          this.init();
      },
    },
    currentSportType: {
      handler(newVal, oldVal) {
        this.init();
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLogin",
      "userInfo",
      "isMobile",
      "isShowFavModal",
      "currentLocale",
      "userSwitchCountryLang",
      "currentSportType",
    ]),
  },
  methods: {
    ...mapActions([
      "getFavTeamList",
      "getFavBasketballTeamList",
      "commonVerifyLogin",
      "unFavTeam",
      "unFavBasketballTeam",
      "toastrFavChangeEvent",
      "toggleFavModal",
    ]),
    init() {
      this.commonVerifyLogin();
      this.getFavTeamListData();
    },
    getFavTeamListData() {
      let params = {
        token: this.userInfo.token,
        timeZone: this.$tools.getCurrentTimeZone(),
      };

      this.$nextTick(async () => {
        let result;
        if (this.currentSportType == "football")
          result = await this.getFavTeamList(params);
        else if (this.currentSportType == "basketball")
          result = await this.getFavBasketballTeamList(params);

        if (!result.result.length) {
          this.isNoData = true;
        } else {
          this.favList = result.result;
          this.isNoData = false;
        }
      });
    },
    toggleModal(redirection) {
      this.toggleFavModal();
      if (!redirection) {
        !this.isShowFavModal && this.getFavTeamListData();
      }
    },
    favHandler(id, isFav, titleType, name) {
      let params = {
        token: this.userInfo.token,
        timeZone: this.$tools.getCurrentTimeZone(),
        teamId: id,
      };

      this.$nextTick(async () => {
        let res;
        if (this.currentSportType == "football")
          res = await this.unFavTeam(params);
        else if (this.currentSportType == "basketball")
          res = await this.unFavBasketballTeam(params);

        if (res.result.businessCode === -1) {
          //fail
          // level2.isFavMatch = true;
        } else {
          let paramsToastr = {
            isOpen: true,
            msg: name,
            isAddedFav: false,
          };

          this.toastrFavChangeEvent(paramsToastr);
          this.init();
        }
      });
    },
  },
};
</script>

<style scoped></style>
